<template>
  <div>
    <pagination
      class="text-center"
      :total-count="totalCount"
      :page-size.sync="pageSize"
      :current-page.sync="pageNumber"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <el-table style="width: 100%;"
              v-loading="loading"
              :data="items"
              :height="tableHeight"
              :default-sort="{prop: 'createDate', order: 'descending'}"
              @sort-change="handleSortChange">
      <el-table-column label="" width="50">
        <template slot-scope="scope">
          <el-button type="text" icon="el-icon-edit"
                      @click="handleEdit(scope.$index, scope.row)"></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="createDate"
                       label="Дата"
                       sortable="custom"
                       min-width="7"
                       :formatter="dateTimeRenderer" />
      <el-table-column prop="userName"
                       label="Логин"
                       sortable="custom"
                       min-width="7" />
      <el-table-column prop="email"
                       label="E-mail"
                       sortable="custom"
                       min-width="7" />
      <el-table-column prop="organizationName"
                       label="Наименование организации"
                       sortable="custom"
                       min-width="5" />
    </el-table>
  </div>
</template>

<script>
import formatters from '@/mixins/formatters';
import tableResize from '@/mixins/tableResize.js';
import pagination from '@/components/Ui/Pagination';
import regApi from '@/api/administration/userRegistration';

export default {
  name: 'UserRegistration',
  mixins: [formatters, tableResize],
  components: { pagination },
  data() {
    return {
      loading: false,
      items: [],
      pageNumber: 1,
      pageSize: 50,
      totalCount: 0,
      sortField: 'createDate',
      sortDesc: true,
    };
  },
  async mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.getTableHeight();
    });
    await this.getUserRegistrations();
    this.tableHeight = this.getTableHeight();
  },
  methods: {
    async getUserRegistrations() {
      this.loading = true;
      const res = await regApi.getUserRegistrations(
        this.pageNumber,
        this.pageSize,
        this.sortField,
        this.sortDesc,
      );
      if (res.data) {
        this.items = res.data.items;
        this.totalCount = res.data.totalCount;
      }
      this.loading = false;
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getUserRegistrations();
    },
    async handleCurrentChange() {
      await this.getUserRegistrations();
    },
    async handleSortChange(event) {
      this.sortField = event.prop;
      this.sortDesc = event.order === 'descending';
      await this.getUserRegistrations();
    },
    handleEdit(index, row) {
      this.$router.push({ path: '/administration/user-registrations/edit', query: { id: row.id } });
    }
  }
};
</script>

<style scoped>

</style>
